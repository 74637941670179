import {Link} from "koye";
import LogoHeader from "../images/logo.png";
import PlayStoreWhite from "../images/play_store_icon_white.png";
import AppStoreWhite from "../images/app_store_icon_white.png";
import {Image2} from "./image";
// import Header from "../images/header_terms.png";
import React from "react";


const SecondaryHeader = () => (
    <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Image2 src={"header_terms.png"} style={{display: 'flex', flex: 1}} className={"main-404"}/>
        <div style={{position: 'absolute', top: 0, width: '100%', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{maxWidth: 960, width: '100%', display: 'flex', flex: 1, padding: `1.05rem 1.0875rem`}}>
                <div style={{width: '50%', display: 'flex', alignItems: 'center'}}>
                    <Link to="/" style={{textDecoration: `none`, flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                        <img src={LogoHeader} style={{marginBottom: 0}} width={35} height={40} alt=""/>
                        <span className={"secondary-header-logo"} style={{marginLeft: '0.725rem', color: '#fff', fontSize: 45, fontFamily: 'RooneySans, sans-serif'}}>Koye</span>
                    </Link>
                </div>
                <div style={{width: '50%', textAlign: 'center', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <a style={{
                            color: `white`,
                            textDecoration: `none`,
                        }}
                        href="https://play.google.com/store/apps/details?id=app.koye.android"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={PlayStoreWhite} alt="" className={"icon-size button button-white margin-right-icon"}/>
                    </a>

                    <a style={{
                            color: `white`,
                            textDecoration: `none`,
                        }}
                        href="https://apps.apple.com/us/app/koye/id1503392571"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={AppStoreWhite} alt="" className={"icon-size button button-white"}/>
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default SecondaryHeader
