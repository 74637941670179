import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import SecondaryHeader from "../components/secondary-header";

const Legal = () => (
    <Layout>
        <SEO title="Terms & Privacy" />
        <div id="terms"/>
        <SecondaryHeader/>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '5%'}}>
            <h3 style={{fontSize: '160%'}}>Terms of Service</h3>
        </div>
        <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
            <div style={{maxWidth: 960, width: '100%', display: 'flex', flex: 1, padding: `1.05rem 1.0875rem 0`, flexDirection: 'column'}}>
                <div>

                    <h5>These Terms of Use are brought by Koye Corp. (Registered Office of the corporation in the
                        United States of America, State of Delaware, County of New Castle, City of Wilmington, 251
                        Little Falls Drive Street, Zip Code 19808) (COMPANY), the owner of KOYE Software
                        (&quot;KOYE Software&quot;).</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>1. ACCEPTANCE OF TERMS OF USE FOR KOYE SOFTWARE</h3>

                    <h5>1.1 You must read these KOYE Software Terms of Use (&quot;Terms&quot;) carefully. These terms are
                        entered into by and between COMPANY and You, and You accept them by: (a) installing
                        KOYE Software; (b) using KOYE Software in any other manner; and/or (c) acknowledging
                        agreement with these terms. If You do not agree to all of these terms, do not start any use of
                        KOYE Software.</h5>

                    <h5>1.2 KOYE Software includes without limitation: (a) various audio recording, transmission,
                        reproduction and call services accessible via KOYE Software (&quot;Services&quot;);
                        (b) information such as technical, contractual, product, program, pricing,
                        marketing, and other valuable information (&quot;Information&quot;); and (c)
                        content such as data, text, sound, geolocation, software or other materials (&quot;Content&quot;).
                        COMPANY controls and operates its KOYE Software from various locations and makes no representation that
                        KOYE Software is appropriate or available for use in all locations. COMPANY&#39;s products and services
                        may not be available in Your location, and deliverables may vary with locations.
                        In the event that You are using KOYE Software on behalf of Your employer, you represent and warrant that
                        You are authorized to accept these Terms on Your employer&#39;s behalf, and that Your employer agrees to indemnify
                        You and COMPANY for violations of these Terms.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>2. YOUR OBLIGATIONS AND CONDUCT</h3>

                    <h5>2.1 In consideration of Your use of KOYE Software, You agree to: (a) sign-up to KOYE Software using
                        pre-existing account on some other social network such as Google, Facebook, Instagram, etc.
                        (the &quot;Initial Network&quot;) which is used each time you are accessing KOYE Software (the &quot;User Account&quot;).
                        Please keep in mind that Your User Account is linked to one account on the Initial Network and that if
                        You are using accounts from two or more Initial Networks such account shall be treated as a separate
                        User Account provided that these accounts in Initial Networks do not use the same e-mail address
                        (the &quot;Linked Accounts&quot;). Likewise, if you have more than one account on one Initial Network,
                        each of such account shall be treated as a separate User Account (b) respect all rules, obligations,
                        and limitations related to Your User Account as determined by the terms of use on the Initial Network.
                        For instance, Your Initial Network may have certain restrictions in respect to user features (such as age)
                        and You must comply to such restrictions in order to have viable User Account that shall be used for access to
                        KOYE Software; (c) provide accurate, current, and complete information about Yourself as it may be prompted
                        by a registration form in your Initial Network (the &quot;Registration Data&quot;); (d) accept all risks of unauthorized
                        access to information and Registration Data. You have sole responsibility for adequate protection and backup of
                        data and/or equipment used in connection with Initial Network and/or KOYE Software; (e) access
                        KOYE Software by using Your User Account on a maximum of 8 different devices simultaneously.
                        Please note that it is not possible to log in KOYE Software using Your User Account and/or Linked Accounts
                        on more than 8 devices simultaneously; (f) not use KOYE Software if you are not at least 13 years old.
                        Furthermore, You should keep in mind that depending on the country of Your residence legal age for use of
                        KOYETO Software may differ and You therefore are obliged to respect such limitations and not use KOYETO
                        Software unless You are of appropriate legal age in Your jurisdiction.
                    </h5>

                    <h5>2.2 You are entirely responsible for all Content that You upload, post, or otherwise transmit via KOYE
                        Software. You agree not to upload, post, or otherwise transmit via KOYE Software Content that: (a) is
                        inaccurate, harmful, obscene, pornographic, defamatory, racist, violent, offensive, harassing, or otherwise objectionable to
                        COMPANY or other users of KOYE Software; (b) includes unauthorized disclosure of personal information and unauthorized disclosure of
                        sensitive personal data or personal data related to children; (c) relates to third party and such third party has not given consent for the use of such Content;
                        (d) violates or infringes anyone&#39;s intellectual property rights; or (e) contains software viruses or any other computer code, files or programs designed to interrupt,
                        destroy or limit the functionality of any computer software or hardware or telecommunications equipment. COMPANY
                        reserves the right to edit or remove Content that violates these Terms or that contains third-party commercial advertisements.
                    </h5>

                    <h5>2.3 You agree that You will not use KOYE Software to: (a) transmit spam, bulk or unsolicited communications;
                        (b) pretend to be COMPANY or any other natural or legal person, or spoof COMPANY&#39;s identity or identity of any
                        other natural or legal person; (c) forge headers or otherwise manipulate identifiers (including URLs) in order to
                        disguise the origin of any Content transmitted through KOYE Software; (d) misrepresent your affiliation with any person or
                        entity; (e) disrupt the normal flow of dialogue or otherwise act in a manner that negatively affects other users&#39; ability
                        to use KOYE Software; (f) engage in activities that would violate any fiduciary relationship, any applicable national or
                        international law, or any regulations having the force of law, including but not limited to attempting to compromise the
                        security of any networked account or site, operating an illegal lottery or gambling operation, stalking, or making threats
                        of harm; or (g) collect or store personal data about other users unless specifically authorized by such users.</h5>

                    <h5>2.4 If You do not use Your User Account for accessing KOYE Software for more than 90 days in a row,
                        Your User Account shall be deleted as well as all Your user data. Please note that deletion of your User
                        Account cannot in any way affect your account on Initial Network.
                    </h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>3. CONFIDENTIALITY OF KOYE SOFTWARE INFORMATION</h3>

                    <h5>3.1 You may obtain direct access via KOYE Software to certain confidential information of COMPANY
                        and its suppliers and affiliates, including without limitation to technical, contractual, product,
                        program, pricing, marketing and other valuable information that should reasonably be understood as confidential
                        (&quot;Confidential Information&quot;). You must hold Confidential Information in strict confidence.
                        Title to Confidential Information remains with the COMPANY and its suppliers and affiliates.
                    </h5>

                    <h5>3.2 Your obligations regarding Confidential Information will remain in force upon deletion of your
                        User Account and/or upon expiry or termination of these Terms for as long as the Confidential Information
                        remains confidential. Upon termination of the Terms or upon COMPANY&#39;s written request,
                        You must cease the use of the Confidential Information and return it to the COMPANY or destroy it.</h5>

                    <h5>3.3 The Terms impose no obligation upon You with respect to Confidential Information that You can
                        establish by legally sufficient evidence: (a) You possessed prior to Your receipt from COMPANY,
                        without an obligation to maintain its confidentiality; (b) is or becomes generally known to the
                        public through no act or omission by You, or otherwise without violation of these Terms; (c)
                        You obtained from a third party who had the right to disclose it, without an obligation to keep such
                        information confidential; (d) You independently developed without the use of Confidential Information and
                        without the participation of individuals who have had access to it, or (e) in response to a valid order by a
                        court or other authority, as required by law, or as necessary to establish the rights of either party
                        under these Terms and as disclosed after prior notice to COMPANY and with time adequate to afford
                        COMPANY the opportunity to object to the disclosure. Each disclosure of Confidential Information
                        provided in section 3.3 (e) must be minimal and sufficient for achieving the requested purpose and in
                        the event that such purpose may be achieved by other means, You are obliged to use those other means.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>4. CONTENT SUBMITTED TO KOYE SOFTWARE</h3>

                    <h5>4.1 COMPANY does not claim ownership of the Content You place in KOYE Software and shall have no
                        obligation of any kind with respect to such Content. Unless otherwise stated herein, any Content
                        You provide in connection with KOYE Software shall be deemed to be provided on a non-confidential basis.
                        You agree that COMPANY is free to use or disseminate such Content on an unrestricted basis for any purpose, and
                        You grant COMPANY and all other users of KOYE Software an irrevocable, worldwide, royalty-free, nonexclusive
                        license to use, reproduce, modify, distribute, transmit, display, perform, adapt, resell and publish such
                        Content (including in digital form). You represent and warrant that you have proper authorization for the
                        worldwide transfer and processing among COMPANY, its affiliates, and thirdparty providers of any information or
                        Content that You may provide in KOYE Software.</h5>

                    <h5>4.2 COMPANY reserves the right, but under no circumstances has an obligation, to monitor, restrict access to, edit or remove any
                        Content that is available via KOYE Software.</h5>

                    <h5>4.3 You are entitled to delete the Content that you have placed in KOYE Software at any moment.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>5. DELIVERY OF COMMUNICATIONS</h3>

                    <h5>5.1 COMPANY will provide reasonable attempt to deliver all communications received via KOYE Software
                        that are addressed to You using the contact information in KOYE Software or otherwise provided by You.
                        However, the COMPANY cannot guarantee delivery of any communications and will be held harmless from any
                        claim or demand related to undelivered communications.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>6. INDEMNITY</h3>

                    <h5>6.1 You acknowledge and agree to indemnify and hold COMPANY and its subsidiaries, affiliates, shareholders,
                        officers, directors, agents, licensors, suppliers, alliance members, other partners, employees and
                        representatives harmless from any claim or demand, including reasonable attorneys&#39; fees, made by any third party
                        due to or arising out of Your Content, Your use of or connection to KOYE Software
                        (including any use by You on behalf of Your employer), Your violation of the Terms, or Your violation of any
                        rights of other users of KOYE Software.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>7. NOTICES; MODIFICATION AND TERMINATION OF SERVICES;
                        AMENDMENT OF TERMS</h3>

                    <h5>7.1 COMPANY may provide notice to You via KOYE Software, email, or any other address you have disclosed to the COMPANY.
                        COMPANY reserves the right at any time to modify, suspend or terminate the Services (or any part thereof), and/or
                        Your use of or access to them, with or without notice. COMPANY reserves the right at any time to terminate the
                        Services if You make any breach of these Terms. COMPANY may also delete, or bar access to or use of, all related
                        Information and files. COMPANY will not be liable to You or any third-party for any modification, suspension, or
                        termination of the Services, or loss of related information. COMPANY may amend these Terms at any time by providing
                        the amended terms within KOYE Software.</h5>

                    <h5>7.2 By downloading or using KOYE Software, You give COMPANY permission to download and install upgrades, updates, patches,
                        hotfixes, and additional features to improve, enhance, and further develop KOYE Software.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>8. ADVERTISEMENTS AND PROMOTIONS</h3>

                    <h5>8.1 KOYE Software may run advertisements and promotions from third parties in KOYE Software. Your correspondence or
                        business dealings with, or participation in promotions of, advertisers other than COMPANY found in or through the
                        KOYE Software, including payment and delivery of related goods or services, and any other terms, conditions,
                        warranties or representations associated with such dealings, are solely between You and such advertiser.
                        COMPANY is not responsible or liable for any loss or damage of any sort incurred as the result of any such
                        dealings or as the result of the presence of such non- COMPANY advertisers in KOYE Software.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>9. CONTENT PROVIDED VIA LINKS</h3>

                    <h5>9.1 You may find links to other resources in KOYE Software. You acknowledge and agree that COMPANY is not
                        responsible for the availability of such external resources, and does not endorse and is not responsible or
                        liable for any content, advertising, products, or other materials on or available from such resources.
                        COMPANY will not be responsible or liable, directly or indirectly, for any actual or alleged damage or loss
                        caused by or in connection with the use of or reliance on any such content, goods or services available on or
                        through any such resource.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>10. INTELLECTUAL PROPERTY RIGHTS</h3>

                    <h5>10.1 Except as expressly authorized by the COMPANY or by Content providers, You agree not to reproduce, modify,
                        rent, lease, loan, sell, distribute, mirror, frame, republish, download, transmit, or create derivative works of the
                        Content of others, in whole or in part, by any means. You must not modify, decompile, or reverse engineer any software the
                        COMPANY discloses to You, including KOYE Software and You must not remove or modify any copyright or trademark notice,
                        any rights protection mechanism, or any other notice of ownership.</h5>

                    <h5>10.2 &quot;COMPANY&#39;s Trademarks&quot; means all names, marks, brands, logos, designs, trade dress, slogans, domain names,
                        and other designations the COMPANY uses in connection with its products and services, including KOYE Software.
                        You may not remove or alter any COMPANY&#39;s Trademarks, or co-brand your own products or material with COMPANY&#39;s Trademarks,
                        without COMPANY &#39;s prior written consent. You acknowledge the COMPANY&#39;s rights in COMPANY&#39;s Trademarks and
                        agree not to use any of COMPANY&#39;s Trademarks without COMPANY&#39;s prior written consent. You agree not to incorporate any
                        COMPANY&#39;s Trademarks into Your trademarks, service marks, company names, Internet addresses, domain names,
                        or any other similar designations, for any use whatsoever.</h5>

                    <h5>10.3 COMPANY is committed to respecting others&#39; intellectual property rights, and we expect our users to do the same.
                        If You believe that Your work has been copied in a way that constitutes copyright infringement in KOYE Software,
                        please contact COMPANY via email at: contact@koye.app.</h5>

                    <h5>10.4 Unless explicitly stated herein, nothing in these Terms shall be construed as conferring You any license to
                        intellectual property rights. Permission is granted to display, copy, distribute and download Content owned by
                        COMPANY in KOYE Software provided that: (a) the copyright notice pertaining to the Content remains,
                        and a permission notice (e.g., &quot;Used with permission&quot;) is added to such Content; (b) the use of such Content
                        is solely for personal and non-commercial use; (c) such Content will not be copied or posted on any networked
                        computer or published in any medium, except as explicitly permitted by valid permission or license covering such materials;
                        and (d) no modifications are made to such Content. This permission terminates automatically without notice if
                        You breach any of the terms or conditions in this Section 10.4. Upon termination, You must immediately destroy any downloaded,
                        copied and/or printed Content.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>11. DISCLAIMER OF WARRANTIES</h3>

                    <h5>11.1 Your use of KOYE Software is at Your sole risk unless otherwise explicitly stated. KOYE
                        Software, including the Information, Services, and Content is provided on an &quot;AS IS,&quot; &quot;AS
                        AVAILABLE,&quot; and &quot;WITH ALL FAULTS&quot; basis. COMPANY disclaims all express or implied conditions,
                        representations, and warranties of any kind, including any implied warranty or condition of
                        merchantability, satisfactory quality, fitness for a particular purpose, or noninfringement.
                        COMPANY makes no representations, warranties, conditions or guarantees as to the usefulness
                        quality, suitability, truth, accuracy or completeness of KOYE Software, Information, Services
                        and/or Content.</h5>

                    <h5>11.2 COMPANY makes no warranty or representation that: (a) KOYE Software will be uninterrupted,
                        timely, secure, or error-free; (b) the results that may be obtained from the use of KOYE
                        Software will be accurate or reliable; (c) the quality of any products, Services, Content,
                        Information, or other material purchased or obtained from KOYE Software will meet your
                        expectations or requirements; or (d) any errors in KOYE Software will be corrected.</h5>

                    <h5>11.3 You assume all risk for any damage to Your computer system or loss of data that results
                        from obtaining any Content from KOYE Software, including any damages resulting from computer
                        viruses.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>12. AUDIO RECORDINGS DISCLAIMER</h3>

                    <h5>12.1 You should always keep in mind that in certain jurisdictions, recording of audio files
                        containing voice and/or Content of third parties may be illegal if such third parties are not
                        properly informed in advance or if their consent is not sought.</h5>

                    <h5>12.2 Users are encouraged to always inform third parties and/or seek their consent for all
                        recordings that might occur in their use of KOYE Software.</h5>

                    <h5>12.3. COMPANY shall not be held liable and shall not be responsible for any failure of any user
                        to inform third parties or to seek consent from third parties in respect for all recordings made
                        through KOYE Software. You acknowledge and agree to indemnify and hold COMPANY and its
                        subsidiaries, affiliates, shareholders, officers, directors, agents, licensors, suppliers,
                        alliance members, other partners, employees and representatives harmless from any claim or
                        demand, including reasonable attorneys&#39; fees, made by any third party due to or arising out of
                        Your failure to inform third parties or to seek consent from third parties in respect for all
                        recordings made through KOYE Software.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>13. LIMITATION OF LIABILITY</h3>

                    <h5>13.1 To the full extent permitted by law, COMPANY is not liable for any direct, indirect,
                        punitive, special, incidental, consequential, or exemplary damages (including, without
                        limitation to, loss of business, revenue, profits, goodwill, use, data, electronically
                        transmitted orders, or any other loss of economic advantage) arising out of or in connection
                        with KOYE Software, even if the COMPANY has previously been advised of, or reasonably could have
                        foreseen, the possibility of such damages, however they arise, whether in breach of contract or
                        in tort (including negligence), including without limitation to damages due to: (a) the use of
                        or the inability to use KOYE Software; (b) the cost of procurement of substitute goods and
                        services resulting from any goods, data, information or services purchased or obtained, or
                        messages received or transactions entered into, through or from KOYE Software; (c) statements or
                        conduct of any third party in KOYE Software, including without limitation to unauthorized access
                        to or alteration of transmissions or data, malicious or criminal behavior, or false or
                        fraudulent transactions; or (d) Content or Information you may download, use, modify or
                        distribute.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>14. COMPANY&#39;S PRIVACY POLICY</h3>

                    <h5>14.1 You consent to the collection, processing, and storage by COMPANY of Your personal
                        information in accordance with the terms of the COMPANY&#39;s Privacy Policy. You agree to comply
                        with all applicable laws and regulations, and the terms of COMPANY&#39;s Privacy Policy, with
                        respect to any access, use and/or submission by You of any personal information in connection
                        with KOYE Software.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>15. GENERAL TERMS</h3>

                    <h5>15.1 The Terms constitute the entire agreement between You and the COMPANY relating to their
                        subject matter, and cancel and supersede any prior versions of the Terms. No modification to the
                        Terms will be binding, unless in writing and provided in KOYE Software and/or on KOYE’s website.
                        You must not assign or otherwise transfer the Terms or any right granted hereunder. You also may
                        be subject to additional terms and conditions that may apply when You use KOYE Software or
                        third-party products or services.</h5>

                    <h5>15.2 You agree that any material breach of Sections 2, 3, 4, 6, 10 and 12 of the Terms may
                        result in irreparable harm to COMPANY for which damages would be an inadequate remedy and,
                        therefore, in addition to its rights and remedies otherwise available at law, COMPANY will be
                        entitled to equitable relief, including both a preliminary and permanent injunction, if such a
                        breach occurs. You waive any requirement for the posting of a bond or other security if COMPANY
                        seeks such an injunction.</h5>

                    <h5>15.3 These Terms are construed in accordance with the laws of the State of California, United
                        States of America, and the governing law for Terms is the law of the State of California, United
                        States of America. Rules on conflict of law and the rules provided in United Nations Convention
                        on Contracts for the International Sale of Goods will not apply to these Terms. Exclusive
                        jurisdiction for all disputes between You and the COMPANY arising from or in connection with
                        these Terms or its validity (including disputes relating to claims under the law of torts),
                        shall have courts of State of California, United States of America.</h5>

                    <h5>15.4 Rights and obligations under the Terms which by their nature should survive will remain in
                        full effect after termination or expiration of the Terms, including but not limited to rights
                        and obligations stipulated in Sections 3, 4, 6, 10, 11, 12 and 13.</h5>

                    <h5>15.5 Any express waiver or failure to exercise promptly any right under the Terms will not
                        create a continuing waiver or any expectation of non-enforcement. If any provision of the Terms
                        is held invalid by any law or regulation of any government, or by any court or arbitrator, the
                        parties agree that such provision will be replaced with a new provision that accomplishes the
                        original business purpose, and the other provisions of the Terms will remain in full force and
                        effect.</h5>
                </div>

            </div>
        </div>

        <div id="privacy" style={{height: 100, width: '100%'}}/>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <h3 style={{fontSize: '160%'}}>Privacy Policy</h3>
        </div>
        <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
            <div style={{maxWidth: 960, width: '100%', display: 'flex', flex: 1, padding: `1.05rem 1.0875rem`, flexDirection: 'column'}}>

                <div>

                    <h5>This policy is brought by Koye Corp. (Registered Office of the corporation in the United States
                        of America, State of Delaware, County of New Castle, City of Wilmington, 251 Little Falls Drive
                        Street, Zip Code 19808) (COMPANY), and it describes how we collect, use, share and secure the
                        personal information we process to support KOYE software applications (&quot;KOYE Software&quot;).</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>WHAT KINDS OF INFORMATION DO WE COLLECT?</h3>

                    <h5>To provide you KOYE Software, we must process certain information about you. The types of
                        information we collect depend on how you use KOYE Software. You can learn how to access and
                        delete the information we collect upon request.</h5>

                    <h5><b>Things you and other users provide</b></h5>

                    <ul>
                        <li>
                            <h5><b>Information and content you provide:</b> We collect the content, communications, and
                                other information you provide when you use KOYE Software, including when you access KOYE
                                Software using pre-existing account on other social networks such as Google, Facebook,
                                Instagram, etc. (&quot;Initial Network&quot;) which is used each time you are accessing KOYE
                                Software (“User Account”). When you access KOYE Software using your account from Initial
                                Network, only basic information related to such account are available to us, and a
                                unique User ID is assigned to your User Account. Your User Account is anonymous to other
                                users that cannot access information related to your account on Initial Network and they
                                can only see your User ID which is not linked with your account from Initial Network.
                                Other users may receive further information about you, only if you share such
                                information. Also, we collect the content, communications, and other information you
                                provide when you create or share content and communicate with others. This can include
                                information in or about the content you provide (like metadata), such as the date a file
                                was created. Our systems automatically process content and communications you and others
                                provide to analyze context and what&#39;s in them for the purposes described in this Policy.
                                To that extent, we may use services of third-parties for analytic purposes in which case
                                certain data is shared with such third-parties. Please note that certain information
                                (such as racial or ethnic origin, sexual orientation, or philosophical beliefs) may be
                                treated as sensitive data and could be subject to special protections under the laws of
                                your country.</h5>
                        </li>

                        <li>
                            <h5><b>Networks and connections:</b> We collect contact information if you choose to upload,
                                sync or import it from a device (such as audio files), which we use for purposes such as
                                connecting you with users you may know.</h5>
                        </li>

                        <li>
                            <h5><b>Your usage:</b> We collect information about how you use KOYE Software, such as the
                                types of content you use or engage with; the features you use; the actions you take; the
                                User Accounts you interact with; and the time, frequency and duration of your
                                activities.</h5>
                        </li>

                        <li>
                            <h5><b>Things other users do and information they provide about you:</b> We also receive and
                                analyze content, communications, and information that other users provide when they use
                                KOYE Software. This can include information about your User Account in situations where
                                other users interact with you.</h5>
                        </li>
                    </ul>

                    <h5></h5>

                    <h5><b>Device Information</b></h5>

                    <h5>We collect information from and about the computers, phones, and other web-connected devices you
                        use that integrate with KOYE Software, and we combine this information across different devices
                        you use. For example, we use information collected about your use of KOYE Software on your phone
                        to personalize better the content or features you see when you use KOYE Software on another
                        device, such as your laptop or tablet.</h5>

                    <h5><b>Information we obtain from these devices includes:</b></h5>

                    <ul>
                        <li>
                            <h5><b>Device attributes:</b> information such as the operating system, hardware and
                                software versions, battery level, signal strength, available storage space, browser
                                type, app and file names and types, and plugins.</h5>
                        </li>

                        <li>
                            <h5><b>Log data:</b> We collect information that your device sends whenever you use KOYE
                                Software, further refer as &quot;Log data.&quot; This Log data may include, among others,
                                information such as your device&#39;s Internet Protocol &quot;IP&quot; address, the time and date
                                that you access to KOYE Software, and the time spent using KOYE Software. We connect
                                personal information to information gathered in our log files as necessary to improve
                                KOYE Software.</h5>
                        </li>

                        <li>
                            <h5><b>Device operations:</b> information about operations and behaviors performed on the
                                device, such as whether a window is foregrounded or backgrounded, or mouse movements
                                (which can help distinguish humans from bots).</h5>
                        </li>

                        <li>
                            <h5><b>Identifiers:</b> unique identifiers, device IDs, and other identifiers.</h5>
                        </li>

                        <li>
                            <h5><b>Device signals:</b> Bluetooth signals, GPS location, and information about nearby
                                Wi-Fi access points, beacons, and cell towers.</h5>
                        </li>

                        <li>
                            <h5><b>Data from device settings:</b> information you allow us to receive through device
                                settings you turn on.</h5>
                        </li>

                        <li>
                            <h5><b>Network and connections:</b> information such as the name of your mobile operator or
                                ISP, language, time zone, mobile phone number, IP address, connection speed and, in some
                                cases, information about other devices that are nearby or on your network.</h5>
                        </li>
                    </ul>

                    <h5></h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>HOW DO WE USE THIS INFORMATION?</h3>

                    <h5>We use the information we receive from you as described below in order to provide and support
                        KOYE Software. The information is used for the following purposes:</h5>

                    <h5><b>Provide, personalize and improve KOYE Software</b></h5>

                    <h5>We use the information we have to deliver KOYE Software, including to personalize features and
                        content. We also use this information to create personalized products that are unique and
                        relevant to you. For example, we use the information on your activities and how you use and
                        interact with KOYE Software.</h5>

                    <ul>
                        <li>
                            <h5><b>Information on various services across KOYE Software:</b> We connect information
                                about your activities related to different services in KOYE Software to provide a more
                                tailored and consistent experience in KOYE Software, wherever you use it. We can also
                                make your experience more seamless, for example, by automatically filling in your
                                registration information (such as your access data).</h5>
                        </li>

                        <li>
                            <h5><b>Location-related information:</b> We use location-related information - such as your
                                current location, to provide, personalize and improve KOYE Software, and to provide its
                                full functionality. Location-related information can be based on things like precise
                                device location (if you&#39;ve allowed us to collect it) and/or IP addresses.</h5>
                        </li>

                        <li>
                            <h5><b>Product research and development:</b> We use the information we have to develop, test
                                and improve our products, including by conducting surveys and research, and testing and
                                troubleshooting new products and features. </h5>
                        </li>

                        <li>
                            <h5><b>Ads and other sponsored content:</b> We use the information we have about you
                                -including information about your interests, actions, and connections to select and
                                personalize ads, offers, and other sponsored content that we may show you.</h5>
                        </li>
                    </ul>

                    <h5></h5>

                    <h5><b>Promote safety, integrity, and security</b></h5>

                    <h5>We use the information we have to verify User Accounts and activity, combat harmful conduct,
                        detect and prevent spam and other bad experiences, maintain the integrity of KOYE Software, and
                        promote safety and security. For example, we use the data we have to investigate suspicious
                        activity or violations of our terms or policies.</h5>

                    <h5><b>Communicate with you</b></h5>

                    <h5>We use the information we have to send you various communications, communicate with you about
                        our products, and let you know about our policies and terms. We also use your information to
                        respond to you when you contact us.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>HOW IS THIS INFORMATION SHARED?</h3>

                    <h5>Your information is shared with others in the following ways:</h5>

                    <h5><b>People and accounts you share content and communicate with</b></h5>

                    <h5>When you share content and communicate using KOYE Software, such information is visible to other
                        users. For example, when you share certain content, such content can be accessed by anyone using
                        KOYE Software if you have made such content visible to other users. As indicated above, such
                        content is linked only to your User Account and other users only see your User ID in such
                        context, unless you decide to share further information. You can share such content outside KOYE
                        Software using other platforms, and in such case, privacy policies of such other platforms shall
                        apply. You can learn more about what information is public and how to control your visibility in
                        KOYE Software by contacting us.</h5>

                    <h5><b>Content others share about you</b></h5>

                    <h5>You should consider what content you share with other users because users who can access your
                        content in KOYE Software can choose to share it with others within or outside KOYE Software,
                        including people and businesses outside the audience you shared with. For example, when you
                        share content with users of KOYE Software, they can download, record, screenshot, or reshare
                        that content to others, in person or in virtual reality experiences.</h5>

                    <h5><b>Change of ownership</b></h5>

                    <h5>If the ownership or control of all or part of KOYE Software or their assets changes, we may
                        transfer your information to the new owner.</h5>

                    <h5><b>Third-party service providers</b></h5>

                    <h5>We may share some collected information with our third–party service providers that we use to
                        provide hosting for maintenance of KOYE Software, backup, storage, analytics, and other
                        services. These thirdparty service providers may have access to or processing your personal
                        information for the purpose of providing these services for us. We do not permit our third-
                        party service providers to use the personal information that we share with them for their
                        marketing purposes or any other purpose not in connection with the services they provide to
                        us.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>HOW CAN I MANAGE OR DELETE INFORMATION ABOUT
                        ME?</h3>

                    <h5>We provide you with the ability to access, rectify, port, and erase your data. You can find more
                        information on accessing, rectifying, porting, and erasing your data in KOYE by contacting us
                        directly.</h5>

                    <h5>We store data until it is no longer necessary to provide our services within KOYE Software, or
                        until your User Account is deleted. This is a case-by-case determination that depends on things
                        like the nature of the data, why it is collected and processed, and relevant legal or
                        operational retention needs.</h5>

                    <h5>When your User Account is deleted, we delete any content you have provided, such as your audio
                        files and geolocation, and you would not be able to recover that information later. Information
                        that others have shared about you, such as audio files, is not a part of your User Account and
                        such information would not be deleted.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>HOW DO WE RESPOND TO LEGAL REQUESTS OR PREVENT
                        HARM?</h3>

                    <h5>In certain situations, we may be required to disclose personal information to the authorities,
                        due to the court orders, legal process or to establish or exercise our legal rights or defend
                        against legal claims, and we might share such an information in order to prevent any illegal
                        activity, suspected fraud, any type of violation of personal or other rights, unauthorized use
                        of KOYE Software, violations of our terms or policies, other harmful or illegal activity, or to
                        protect ourselves (including our rights, property or products), you or others.</h5>

                    <h5>Information we receive about you can be accessed and preserved for an extended period when it is
                        the subject of a legal request or obligation, governmental investigation, or investigations of
                        possible violations of our terms or policies, or otherwise to prevent harm. We also retain
                        information from accounts disabled for terms violations for at least a year to prevent repeat
                        abuse or other term violations.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>HOW DO WE OPERATE AND TRANSFER DATA AS PART OF OUR
                        GLOBAL
                        SERVICES?</h3>

                    <h5>We may share information globally, within the COMPANY and/or users that you connect and share
                        with around the world in accordance with this policy. Your information may, for example, be
                        transferred or transmitted to, or stored and processed in countries outside of where you live
                        for the purposes as described in this policy. These data transfers are necessary to provide the
                        services set forth in Terms of Use of KOYE Software in order to provide our products to you. We
                        utilize standard contract clauses, rely on the European Commission&#39;s adequacy decisions about
                        certain countries, as applicable, and obtain your consent for these data transfers in accordance
                        with applicable law.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>HOW WILL WE NOTIFY YOU OF CHANGES TO THIS
                        POLICY?</h3>

                    <h5>We&#39;ll notify you before we make changes to this policy and give you the opportunity to review
                        the revised policy before you choose to continue using KOYE Software.</h5>

                    <h3 style={{fontSize: '100%', textAlign: 'left'}}>HOW TO CONTACT US</h3>

                    <h5>You can learn more about how privacy works in KOYE Software by contacting us. If you have
                        questions about this policy, you can contact us via below provided contacts. Additionally, we
                        may also resolve any disputes you have with us in connection with our privacy policies and
                        practices through direct contact.</h5>

                    <h5><b>contact@koye.app</b></h5>
                    <h5><b>Date of Last Revision: March 10, 2020</b></h5>
                </div>

            </div>
        </div>

    </Layout>
);

export default Legal